<template>
  <div  class="main-templates " v-if="currentTemplate" :key="currentTemplate.id">
    <b-row>
      <b-col :lg="leftSide">
        <contilt-inputs
          :title="currentTemplate.title"
          :generateLabel="currentTemplate.generateLabel"
          :generationLoading="generationLoading"
          :entityReaders="entityReaders"
          generate="true"
          :includeFreeText="freeStyle"
          :freeText="sessionData ? sessionData.inputs['_freeTextInput']: ''"
          @on-generate="generate"
          :userQuota="userQuota"
          ref="contilt-inputs"
          :sampleinputs="sampleinputs"
        ></contilt-inputs>
      </b-col>



      <b-col :lg="rightSide">
        <div v-if="!pageOutputData">
          <b-overlay :show="generationLoading" rounded="sm">
            <div class="misc-inner p-2 p-sm-3">
              <div class="w-100 text-center">
                <h2 class="mb-1">Once done the result will appear here</h2>
                <p class="mb-3">
                  Contilt makes content creation fun. One click and your result
                  will be ready shortly!
                </p>

                <b-img fluid :src="imgUrl" alt="Coming soon page" />
              </div>
            </div>
          </b-overlay>
        </div>

        <div class="main-output"  v-else>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="download-csv"
            variant="primary"
            @click="downloadCSV"
          >
            <feather-icon
              icon="ArrowDownIcon"
              class="mr-50"
            />
            <span class="align-middle">CSV</span>
          </b-button>
          <ContiltOutput
            :template="currentTemplate"
            :templateId="currentTemplate.id"
            :inputsResultsData="inputsResultsData"
            :generationLoading="generationLoading"
            @change-side-size="changeSlides"
            :taskId="taskCallLogId"
            :templateCallId="templateCallLogId"
            :key="generateUUid"
            :outputStructure="outputStructure"
            :outputsFromSer="pageOutputData"
            :main="true"
            :dynamicOutputData="dynamicOutputData"
            @dynamic-generate="dynamicGenerate"
          />
         
          <!-- <add-review></add-review> -->
        </div>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import axios from "@axios";
import ContiltOutput from "./outputsfactory/outputsfactory.vue";
import contiltInputs from "./inputsFactory/inputsFactory.vue";
import AddReview from "../../../contilt/addReview/addreview.vue";
import SocketioService from "./socketio.service";
import { v4 as uuidv4 } from "uuid";
import { BRow, BCol, BImg, BOverlay, BButton, BSpinner } from "bootstrap-vue";
import useAppConfig from "@core/app-config/useAppConfig";
import { onBeforeUnmount } from "@vue/composition-api";
import router from '@/router';
import { ExportToCsv } from 'export-to-csv';
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BSpinner,
    BRow,
    BCol,
    BImg,
    BOverlay,
    ContiltOutput,
    contiltInputs,
    AddReview,
    BButton,
  },
    directives: {
    Ripple,
  },
  setup() {
    onBeforeUnmount(() => {
      SocketioService.disconnect();
    });
    const { userQuota } = useAppConfig();
    return { userQuota };
  },
  methods: {
    getStructure(outputs){
      let structure = null;
      if(outputs["_structure"]){
        structure = {};
        outputs["_structure"].forEach( x=> {
          const pathArr = x.path.split(".");
          let current = structure;
          pathArr.forEach( y =>{
            if(!current[y]){
              current[y] = {}
            }
            current = current[y];
          })
          current["structure"] = x.data;
        })
      }
      return structure;
    },
    apiRequest(){
      this.$refs["apiCodeModal"].showModal(this.currentTemplate, this.inputsResultsData)
    },

    downloadCSV(){
      debugger;
      const options = { 
    fieldSeparator: ';',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: false, 
    showTitle: false,
    title: 'My CSV',
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    // headers: Object.keys(data[0])
  };
      const csvExporter = new ExportToCsv(options);
      let data = [];
      const mainOutputs = document.querySelectorAll(".contilt-output");
      mainOutputs.forEach(x=> {
        let d = {};
        x.querySelectorAll(".inner-output").forEach(y => {
          let title = "";
          let datatext = "";
          if(y.querySelector(".multifield")){
            y.querySelectorAll(".output-renderer").forEach(yy => {
              let post = document.createElement('p');
              const cEl = yy.cloneNode(true);
              const chat = cEl.querySelector(".simple-chat")
              if(chat){
                chat.remove();
              }
              post.innerHTML = cEl.innerHTML.replace(/<br>/g, '\n');
            
              const txt = post.textContent.trim().replace(/\n/g, "|");
              const n = cEl.dataset.name;
              d[n] = txt
            })
          }else {
            try{
              title = y.querySelector("h4").textContent.trim()
            }
            catch(e){}
            try{
              datatext = y.querySelector(".output-renderer").textContent.trim()
            }
            catch(e){}
      
            d[title] = datatext
          }
         
        });
        data.push(d);
      })
  
      csvExporter.generateCsv(data);
    },
    changeSlides(left, right){
      if(left){
        this.leftSide = left;
      }
      if(right){
        this.rightSide = right;
      }
    },
    dynamicGenerate({resultsData, template, nodeId, dynamicOutputId}){
        let self = this;
        this.generateRequest(resultsData, template.id, true, this.$route.query.sessionId, dynamicOutputId, nodeId).then((data) => {
     
          if(data){
              axios.get(`outputs/dynamicOutput/${dynamicOutputId}`).then((d) => {
                
                self.dynamicOutputData.push(d.data);
              })

          }
        }).catch(err => {
          
        })
        



    },
    generateRequest(resultsData, templateId, useSocket, templateCallLogId, dynamicPrefix, nodeId){
      let _this = this;
      return new Promise((resolve1, reject1) => {
        let data = {};
        Object.keys(resultsData).forEach((key) => {
          data[key] = resultsData[key] || "";
        });
  
        const dataToSend = {
          templateId,
          templateCallId: templateCallLogId,
          context: data,
          nodeId: nodeId,
          dynamicPrefix,
          useSocket: useSocket,
        };
        SocketioService.setupSocketConnection();
        let promise = new Promise((reslove)=> reslove())
        if(useSocket){
          SocketioService.getSocket().on("frontendframeworkpath", (data) => {
            debugger;
            const socketData = JSON.parse(data);
            _this.prepareOutputStructureData(socketData.data, socketData.path)
          });
          SocketioService.getSocket().on("frontendframeworkoutputerror", (data) => {
            reject1(data)
          });
          SocketioService.getSocket().on("frontendframeworkoutputend", (data) => {
            resolve1()
            try{
              const jdata = JSON.parse(data);
              if(jdata.dynamicOutputId){
                axios.get(`outputs/dynamicOutput/${jdata.dynamicOutputId}`).then((d) => {
                  _this.dynamicOutputData.push(d.data);
                })
              }
              
              if(jdata && jdata.templateCallId){
                router.replace({
                    params: router.currentRoute.params,
                    query: {...router.currentRoute.query, ...{sessionId: jdata.templateCallId}}
                });
              }
            }catch(e){}
          });
          promise = new Promise((reslove2)=> {
            SocketioService.getSocket().on('connect', () => {
                reslove2(SocketioService.getSocket().id)
            })

          })
        }
        promise.then(socketId => {
          dataToSend.socketId = socketId;
          axios
          .post("/templates/run", dataToSend)
          .then((response) => {
            if (!useSocket) {
              resolve1(response.data);
             
            }

            //update credits
            
            this.$store.dispatch("appConfig/updateUserQuota");
          })
          .catch((error) => {
            reject1(error)
 
          });
        })
        
        
      })
    },
    deepFind(obj, path, addValue=false) {
      var paths = path.split('.')
        , current = obj
        , i;
      for (i = 0; i < paths.length; ++i) {

        if (current[paths[i]] == undefined) {
          current[paths[i]] = {};
          if(addValue){
            current[paths[i]].value = {};
            current = current[paths[i]].value
          }else{
            current = current[paths[i]]
          }
          
        } else {
          current = current[paths[i]];
          if(addValue){
            current = current.value
          }else if(current.structure) {
            delete current.structure;
          }
        }
      }
      return current;
    },
    setValues(outputStructure, pageOutputData){
          if(outputStructure){
            outputStructure.forEach((x) => {
            if (x.type == "multifield") {
              pageOutputData[x.name] = {};
              x.loading = true;
              x.items.forEach((y) => {
                pageOutputData[x.name][y.name] = {
                  value: "",
                  loading: true,
                };
              });
            }
            else if(x.type == "subTemplate" || x.type == "agentTemplate"){
              pageOutputData[x.name] = {
                value: {},
                loading: true,
              };
            } 
            else if(x.name) {
              pageOutputData[x.name] = {
                value: "",
                loading: true,
              };
            }
          });
          }
    },
    prepareOutputStructureData(data, path){
      if(path){
        const name = path.split(".")[0];
        const targetAgent = this.outputStructure.find(x=>x.name == name)
        if(!targetAgent.structure){
          targetAgent.structure = {};
        }
        const current = this.deepFind(targetAgent.structure, path)
        current.structure = data
        targetAgent.triggerKey = uuidv4();
        const currentData = this.deepFind(this.pageOutputData, path, true)
        this.setValues(current.structure.outputs, currentData)
      }
      
    },
    generate(resultsData) {
      this.generateUUid = uuidv4();
      this.generationLoading = true;
      this.pageOutputData = null;
      let self = this;
      let useSocket = true;
        this.currentTemplate.outputs.filter(x=>x.type == "agentTemplate").forEach(x => {
          x.structure = {};
        })
      setTimeout(() => {
        if (useSocket) {
          self.pageOutputData = {};
          this.setValues(self.outputStructure, self.pageOutputData)
         
        }
        this.generateRequest(resultsData, this.currentTemplate.id, useSocket).then((data) => {
          
          if(data){
              this.pageOutputData = data;
              this.generationLoading = false;
          }else {
            this.getSessionDataPromise().then((res) => {
              this.pageOutputData = null;
              setTimeout(()=> {
                this.pageOutputData = res.data.sessionData.outputs;
              }, 0)
              
              this.sessionData = res.data.sessionData;
              this.generationLoading = false;
            })

          }
        }).catch(err => {
           this.generationLoading = false;
        })
        
      }, 0);
    },
    getTemplatePromise() {
      const promise = new Promise((resolve, reject) => {
        return axios
          .get(`/templates/${this.templateId}`)
          .then((response) => {
            if (response.data) {
              resolve(response);
            }
          })
          .catch((error) => {
            this.generationLoading = false;
            reject(error);
          });
      });
      return promise;
    },
    getSessionDataPromise() {
      const promise = new Promise((resolve, reject) => {
        return axios
          .get(`/templatescalllog/${this.sessionId}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
      return promise;
    },
  },
  watch: {
    currentTemplate (old, newVal){
        setTimeout(() => {
          this.inputsResultsData = this.$refs["contilt-inputs"].resultsData
        }, 100);
    },

    templateId(old,newVal){
      this.getTemplatePromise().then(res => {
        if(this.templateData){
          res.data.inputs.forEach(x => {
            if(this.templateData[x.name]){
              x.value = this.templateData[x.name]
            }
          })
        }

        this.currentTemplate = res.data
        setTimeout(() => {
          router.replace({name: "template-to-run", query: null})
        }, 0)
        

        this.$store.commit("templatesStore/setSelectedTemplate", res.data);
      })
    }
  },
  data() {
    return {
      sessionData: null,
      inputsResultsData: null,
      taskCallLogId: null,
      templateCallLogId: null,
      leftSide: 6,
      rightSide: 6,
      downImg: require("@/assets/images/pages/coming-soon.svg"),
      menuHidden: this.$store.state.appConfig.layout.menu.hidden,
      generationLoading: false,
   
      information: null,
      pageOutputData: null,
      currentTemplate: null,
      dynamicOutputData: [],
      generateUUid: uuidv4(),
    };
  },
  computed: {
    freeStyle(){
      return this.currentTemplate.applyFreeText;
    },
    outputStructure(){
      return this.currentTemplate.outputs;
    },
    templateId(){
      return this.$route.params.id;
    },
    templateData(){
      return this.$route.params.templateData;
    },
    sessionId(){
      return this.$route.query.sessionId
    },
    imgUrl() {
      if (this.$store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require("@/assets/images/pages/coming-soon-dark.svg");
        return this.downImg;
      }
      return this.downImg;
    },

    entityReaders() {
      return this.$store.state.templatesStore.selectedTemplate.entityReaders;
    },

    sampleinputs() {
      return this.$store.state.templatesStore.selectedTemplate.inputs;
    },

  },
  beforeUnmount() {
    SocketioService.disconnect();
  },
  // mounted() {
  //   this.$watch(
  //       () => {
  //           return this.$refs["contilt-inputs"].resultsData
  //       },
  //     (val) => {
  //       this.inputsResultsData = val;
  //     }
  //   )
  // },
  created() {
    let _this = this;

    this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", true);

      const selectedTemplatePromise = this.getTemplatePromise();
      let promisesArray = [selectedTemplatePromise];

      if (this.$route.query.sessionId) {
        const selectedSessionPromise = this.getSessionDataPromise();

        promisesArray.push(selectedSessionPromise);
      }

      Promise.all(promisesArray).then((values) => {
        const selected = values[0].data;

        if (values[0]) {
          // selected template data here
          values[0].data.outputs.filter(x=>x.type == "agentTemplate").forEach(x=>{
            x.structure = {};
            x.triggerKey = uuidv4();
          })
          this.currentTemplate = values[0].data;
          this.$store.commit("templatesStore/setSelectedTemplate", this.currentTemplate);

        }
        if (values[1]) {
          //session data here
          // this.pageOutputData = this.$route.params.sessionData.outputs;
          let sessionData = values[1].data.sessionData;
          this.sessionData = sessionData;
          const structure = this.getStructure(sessionData.outputs);
          debugger;
          if(structure){
            this.currentTemplate.outputs.filter(x=>x.type == "agentTemplate").forEach(x => {
              if(structure[x.name]){
                let s = {};
                s[x.name] = structure[x.name];
                x.structure = s;
              }
            })
          }
          
          _this.templateCallLogId = values[1].data.id;
          _this.taskCallLogId = values[1].data.task;
          _this.dynamicOutputData = values[1].data.dynamicOutputs;

          
          if (sessionData && sessionData.inputs && sessionData.outputs) {
            const inputsUpdatedValues = [];
            let helpInput = {};
            selected.inputs.map((input) => {
              helpInput = { ...input };
              helpInput.value = sessionData.inputs[input.name] || input.value;
              inputsUpdatedValues.push(helpInput);
            });
            selected.inputs = [...inputsUpdatedValues];
            
          }
          _this.pageOutputData = sessionData.outputs;
        }
      });
    
  },
  destroyed() {
    this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", this.menuHidden);
    this.$store.commit("templatesStore/setSelectedTemplate", {});
  },
};
</script>
<style>
 .download-csv {
    position: absolute !important;
    top: -48px;
    right: 59px;
    
 }
 .output-renderer{
  white-space: pre-wrap;
 }
</style>